/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-background;
}

@media (prefers-color-scheme: dark) {
  tui-root {
    --tui-primary: theme('colors.primary.light');
    --tui-primary-hover: theme('colors.secondary.light');
    --tui-link: theme('colors.primary.light');
    --tui-link-hover: theme('colors.onPrimaryContainer.light');
    --tui-primary-text: theme('colors.onPrimary.light');
    --tui-secondary-hover: theme('colors.primaryContainer.light');
  }
}

@media (prefers-color-scheme: light) {
  tui-root {
    --tui-primary: theme('colors.primary.light');
    --tui-primary-hover: theme('colors.secondary.light');
    --tui-link: theme('colors.primary.light');
    --tui-link-hover: theme('colors.onPrimaryContainer.light');
    --tui-primary-text: theme('colors.onPrimary.light');
    --tui-secondary-hover: theme('colors.primaryContainer.light');
  }
}

.bo-content {
  @apply mx-auto max-w-7xl px-4 sm:px-6 lg:px-8;
}
